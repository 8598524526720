import React from 'react'

import styled from 'styled-components'

const alphabetArr = [
	'0-9',
	'.',
	...'abcdefghijklmnopqrstuvwxyz'
		.split('')
		.join('.')
		.split('')
]

const Letter = styled.button`
	margin-top: auto;
	padding: 0;
	font-size: 20px;
	line-height: 26px;
	// letter-spacing: 5.22px;
	// text-indent: 5.22px;
	white-space: nowrap;
	text-transform: uppercase;
	display: inline-block;
	margin-block-end: 0.25em;
	margin-block-start: 0.25em;
	background-color: white;
	border: none;
	outline: none;
	:focus,
	:hover {
		border-bottom: solid;
		border-color: ${props => props.theme.colors.primary};
		color: ${props => props.theme.colors.primary};
	}
`

const Dot = styled.p`
	font-size: 20px;
	line-height: 32px;
	letter-spacing: 5.22px;
	text-indent: 5.22px;
	white-space: nowrap;
	text-transform: uppercase;
	display: inline-block;
	margin-block-end: 0.25em;
	margin-block-start: 0.25em;
`

// const Grid = styled.div`
//   display: grid;
//   grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
//   grid-template-rows: auto;
//   grid-gap: 5px;
// `;

// const ExamesWrapper = styled.div`
//   padding: 0.5em;
//   font-size: 12px;
//   text-align: center;
// `;

/**
 * Componente com filtro por letras
 * @param {func} handleChangeLetter - recebe um handle para atualizar quando uma letra for selecionada
 */
export default function FilterByLetter({ handleChangeLetter }) {
	// Sem fitro real dos exames por hora!
	// const [exames, setExames] = useState(ExamesAll);

	// const filterBy = letter => {
	//   let filtered = [];
	//   if (letter === "#")
	//     //number
	//     filtered = ExamesAll.filter(
	//       ex =>
	//         ex.sigla.startsWith(0) ||
	//         ex.sigla.startsWith(1) ||
	//         ex.sigla.startsWith(2) ||
	//         ex.sigla.startsWith(3) ||
	//         ex.sigla.startsWith(4) ||
	//         ex.sigla.startsWith(5) ||
	//         ex.sigla.startsWith(6) ||
	//         ex.sigla.startsWith(7) ||
	//         ex.sigla.startsWith(8) ||
	//         ex.sigla.startsWith(9)
	//     );
	//   else
	//     filtered = ExamesAll.filter(ex =>
	//       ex.sigla.startsWith(letter.toUpperCase())
	//     );
	//   setExames(filtered);
	// };

	const handleClick = letter => () => handleChangeLetter && handleChangeLetter(letter.toLowerCase())
	return (
		<>
			{alphabetArr.map((letter, index) => {
				const component = letter === '.' ? <Dot>{letter}</Dot> : <Letter onClick={handleClick(letter)}>{letter}</Letter>
				return component
			})}
		</>
	)
}
