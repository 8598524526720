import styled, { css } from 'styled-components'

import { Container } from '@material-ui/core'

export const ExamWrapper = styled.div`
	${({ theme }) => css`
		font-family: 'fs_lolaregular' !important;
		padding: 16px; // temporário para testes

		@media (max-width: 500px) {
			padding: 0;
		}
	`}
`

export const Wrapper = styled.div`
	${({ theme }) => css`
		font-family: 'fs_lolaregular' !important;

		.carousel-card {
			padding: ${theme.spacing(2, 1)};
		}

		.react-multiple-carousel__arrow {
			background: ${theme.palette.primary.main};
			margin-top: ${theme.spacing(-13)};

			&:hover {
				background: ${theme.palette.primary.dark};
			}
		}

		.react-multi-carousel-item {
			padding-bottom: 0;

			> article {
				padding: 0;
			}
		}
	`}
`

export const ListWrapper = styled.div`
	${({ theme }) => css`
		margin-top: ${theme.spacing(5)};
		font-family: 'fs_lolaregular' !important;

		.MuiCard-root {
			max-height: ${theme.typography.pxToRem(232)};
			margin-bottom: ${theme.spacing(2)};
			padding: ${theme.spacing(1)};
		}

		.MuiCardContent-root {
			display: flex;
			flex-direction: column;
			flex: 0;

			.MuiTypography-root {
				font-family: 'fs_lolaregular' !important;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
				margin-bottom: ${theme.spacing(1)};

				&:last-child {
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
					margin: 0;
					font-size: ${theme.typography.pxToRem(14)};
					line-height: ${theme.typography.pxToRem(20)};
				}
			}
		}

		.MuiCardActions-root {
			margin: ${theme.spacing(2, 0)};

			.MuiButton-root {
				padding: ${theme.spacing(2, 3)};
				font-size: ${theme.typography.pxToRem(16)};
			}
		}
	`}
`

export const Counter = styled.div`
	${({ theme }) => css`
		font-family: 'fs_lolaregular' !important;		
		margin-bottom: ${theme.spacing(2)};
		padding: ${theme.spacing(1, 3)};
		background-color: ${theme.palette.grey[100]};

		${theme.breakpoints.up('sm')} {
			/* margin: ${theme.spacing(2)}; */
			background-color: ${theme.palette.background.default};
			border-radius: ${theme.typography.pxToRem(4)};
		}
	`}
`

export const CustomContainer = styled(Container)`
	${({ theme }) => css`
		margin-bottom: ${theme.spacing(6)};
		font-family: 'fs_lolaregular' !important;

		${theme.breakpoints.down('sm')} {
			margin: 0;
			padding: 0;
		}

		${theme.breakpoints.up('sm')} {
			padding: 0;
			margin: ${theme.spacing(1)};
		}

		${theme.breakpoints.up('lg')} {
			margin: ${theme.spacing(3)};
		}
	`}
`

export const PaddingContainer = styled.div`
	${({ theme }) => css`
		//padding: ${theme.spacing(2)};
		padding: 16px;  // Temporário para testes
	`}
`

export const InstructionsContainer = styled.div`
	${({ theme }) => css`
		background: #ffffff;
		box-shadow: 0px 0px 8px 3px rgba(117, 117, 117, 0.15);
		border-radius: 24px;
		font-family: 'fs_lolaregular' !important;
	`}
`

export const InfoContainer = styled.div`
	${({ theme }) => css`
		background: #ffffff;
		//box-shadow: 4px 5px 8px -3px rgba(117, 117, 117, 0.15);
		box-shadow: 1px 0px 8px 3px rgba(117, 117, 117, 0.15);
		border-radius: 24px;
		border: 1px solid rgba(117, 117, 117, 0.1);
		padding: 16px;
		font-family: 'fs_lolaregular' !important;
		text-decoration: none !important;

		a {
			display: flex;
			align-items: center;
			font-family: 'fs_lolaregular' !important;
			text-decoration: none !important;
			color: ${theme.colors.primary};
			cursor: pointer;

			svg {
				margin-top: 2px !important;
			}
		}
	`}
`

export const InfoUnitContainer = styled.div`
	${({ theme }) => css`
		background: #ffffff;
		font-family: 'fs_lolaregular' !important;
		//box-shadow: 4px 5px 8px -3px rgba(117, 117, 117, 0.15);
		box-shadow: 1px 0px 8px 3px rgba(117, 117, 117, 0.1);
		border-radius: 24px;
		padding: 16px;
	`}
`

export const NeedSchedule = styled.div`
	${({ theme }) => css`
		font-family: 'fs_lolaregular' !important;		
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 40px;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
		//margin-bottom: ${theme.spacing(2)};
		//margin-top: ${theme.spacing(2)};
		margin-bottom: 16px;
		margin-top: 16px;

		background: #1F9E94;

		&.need {
			background: #f26529;
		}
	`}
`

export const ExamsContainer = styled(Container)`
	${({ theme }) => css`
		padding-left: ${theme.spacing(2)};
		font-family: 'fs_lolaregular' !important;
	`}
`

export const Pagination = styled.div`
	${({ theme }) => css`
		display: flex;
		align-items: center;
		justify-content: center;
		margin: ${theme.spacing(5, 0)};

		.Mui-selected {
			border-color: ${theme.palette.primary.main};
		}
	`}
`
export const Spacing = styled.section`
	${({ theme }) => css`
		margin-top: 24px;
	`}
`

export const SpacingMin = styled.section`
	${({ theme }) => css`
		margin-top: 12px;
	`}
`

export const UList = styled.ul`
	${({ theme }) => css`
		//margin-top: ${theme.spacing(2)};
		margin-top: 16px;
		list-style-position: inside;
	`}
`

export const HTMLRender = styled.div`
	${({ theme }) => css`
		margin-bottom: 24px;
		font-family: 'fs_lolaregular' !important;

		> p {
			font-size: ${theme.typography.pxToRem(16)};
			line-height: ${theme.typography.pxToRem(28)};

			> a {
				font-weight: 700;
				color: ${theme.palette.primary.main};
			}
		}
	`}
`

export const BgGray = styled.div`
	${({ theme }) => css`
		font-family: 'fs_lolaregular' !important;
		padding: ${theme.spacing(2, 6)};
		background-color: ${theme.palette.background.default};
	`}
`
