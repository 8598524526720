import React from 'react'
import styled from 'styled-components'
import formatString from '../../utils/formatString'

const ListaExame = styled.span`
	color: #464646;
	font-size: 14px;
	position: relative;
	margin-bottom: 20px;
	display: flex;
	flex-wrap: wrap;

	@media (max-width: 767.98px) {
		margin: 0 auto;
		font-size: 13px;
		line-height: 1.2;
	}

	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
`

const SpanExame = styled.span`
	line-height: 1.5;

	:not(:first-of-type) {
		:before {
			content: ' ';
		}
	}

	:not(:last-of-type) {
		:after {
			content: '| ';
		}
	}
`
/**
 * A partir de um array de sinonimias, monta o span com os divisores e as reticências.
 */
export default function Sinonimias({ array }) {
	return (
		!!array.length && (
			<React.Fragment>
				<ListaExame>
					<b>Outros nomes:&nbsp;</b>
					{array.map(s => (
						<SpanExame key={`sinonimias-item-${s}`}>{`${formatString(s)} `}</SpanExame>
					))}
				</ListaExame>
			</React.Fragment>
		)
	)
}
