import React from 'react'
//import { IoIosArrowRoundForward } from 'react-icons/io'

import styled from 'styled-components'
import { Link } from 'gatsby'
import Sinonimias from './Sinonimias'

const DivExame = styled.div`
	padding: 20px 30px 15px;
	border-radius: 16px;
	border: 1px solid ${props => props.theme.colors.graye2};
	margin: 0 0 15px 0;
	text-decoration: none;
	box-shadow: 4px 5px 5px -3px rgba(0, 0, 0, 0.1);

	/* // :first-child {
	// 	margin: 0;
	// } */

	@media (max-width: 767.98px) {
		padding: 20px 15px 15px 5px;
	}
`

const BtnsExame = styled.div`
	display: flex;
	justify-content: flex-end;
`

const H3Exame = styled.h3`
	color: ${props => props.theme.colors.black_fonts};
	font-size: 20px;
	font-weight: bold;

	@media (max-width: 767.98px) {
		font-size: 18px;
		font-weight: normal;
		margin-block-start: 0;
	}
`
const ClickCard = styled(Link)`
	text-decoration: none;

	&:hover {
		color: ${props => props.theme.colors.primary};
	}

	&:focus {
		text-decoration: none;
	}
`

const MaisInfosA = styled(Link)`
	font-size: 14px;
	font-weight: bold;
	color: ${props => props.theme.colors.primary};
	text-decoration: none;

	&:hover {
		color: ${props => props.theme.colors.primary};
	}

	&:focus {
		text-decoration: none;
	}

	svg {
		width: 40px;
		height: 40px;
	}

	@media (max-width: 767.98px) {
		display: none;
	}

	display: flex;
	align-items: center;
`

const Hr = styled.div`
	border-bottom: 1px solid ${props => props.theme.colors.graye2};
	margin-bottom: 15px;

	@media (max-width: 767.98px) {
		display: none;
	}
`

/**
 * Componente exclusivo que desenha o card de exame
 * @param {string} nome - nome do exame
 * @param {string} sinonimia -
 * @param {string} id - id do exame
 * @param {func} onClick - callback para click no card, quando versao mobile, ou no botao
 */
function CardExames({ onClick, nome, sinonimia, id, slug, setPageSession }) {
	const sinonimiasArray = [...sinonimia]

	return (
		<DivExame onClick={onClick}>
			<ClickCard id={`more-information-${nome}`} to={`/exames/${slug}`} onClick={setPageSession}>
				<>
					<H3Exame>{nome}</H3Exame>
					<Sinonimias array={sinonimiasArray} />
				</>
				<Hr />
				<BtnsExame>
					<MaisInfosA id={`more-information-${nome}`} to={`/exames/${slug}`} onClick={setPageSession}>
						Preparo e mais
					</MaisInfosA>
					{/* <AgendarBtn>Agendar</AgendarBtn> */}
				</BtnsExame>
			</ClickCard>
		</DivExame>
	)
}

export default CardExames
